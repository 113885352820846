<template>
    <div v-if="filteredSymbols.length > 0">
        <section class="homepage-market d-none d-md-block" :id="this.futures?'futures_symbolList':''">
            <div class="market-wrap">
                <dl>
                    <dt>
                        <div>{{ $t('orders.label_symbol') }}</div>
                        <div class="text-center">{{ $t('home.price') }}</div>
                        <div class="text-center">{{ $t('home.24h_change') }}</div>
                        <div class="text-center">{{ $t('home.24h_low') }}</div>
                        <div class="text-center">{{ $t('home.24h_high') }}</div>
                        <div class="text-center">{{ $t('home.symbol_action') }}</div>
                    </dt>
                    <dd v-for="(symbol, index) in filteredSymbols" :key="index" @click="gotoTrade(symbol)">
                        <router-link :to="symbol.getRoutePath()">
                            <div class="text-uppercase d-flex align-items-center">
                                <div class="pe-2"><token-icon :symbol="symbol" /></div>
                                <div>{{ symbol.metadata.name }}</div>
                            </div>
                            <div class="d-flex justify-content-center" growing-ignore="true">${{ symbol.price }}</div>
                            <div class="d-flex justify-content-center" growing-ignore="true">
                                <span :class="symbol.up ? 'rate up' : 'rate down'">
                                    {{ symbol.price_change_pct }}
                                </span>
                            </div>
                            <div class="d-flex justify-content-center" growing-ignore="true">
                                <span :class="symbol.up ? 'rate up' : 'rate down'">
                                    {{ symbol.day_low }}
                                </span>
                            </div>

                            <div class="d-flex justify-content-center" growing-ignore="true">
                                <span :class="symbol.up ? 'rate up' : 'rate down'">
                                    {{ symbol.day_high }}
                                </span>
                            </div>
                            <div class="buy d-flex justify-content-center"><span>{{$t('general.trade')}}</span></div>
                        </router-link>
                    </dd>
                </dl>
            </div>
        </section>
        <section class="homepage-market-sm d-lg-none d-md-none" :id="this.futures?'futures_symbolList':''">
            <dl>
                <dd class="title">
                    <dl id="futures-symname">{{ $t('orders.label_symbol') }}</dl>
                    <dl id="futures-price">{{ $t('home.price') }}</dl>
                    <em id="futures-change">{{ $t('home.24h_change') }}</em>
                </dd>
                <dd v-for="(symbol, index) in filteredSymbols" :key="index" @click="gotoTrade(symbol)">
                    <dl>
                        <dt class="text-uppercase d-flex align-items-center token-name">
                            <div class="pe-2"><token-icon :symbol="symbol" /></div>
                            <div>{{ symbol.metadata.name }}</div>
                        </dt>
                        <!-- <dd growing-ignore="true">24H Vol {{ symbol.day_vol }}</dd> -->
                    </dl>
                    <dl>
                        <dt growing-ignore="true">${{ symbol.price }}</dt>
                        <dd growing-ignore="true">{{ symbol.price_change }}</dd>
                    </dl>
                    <span v-if="futures" :class="symbol.up ? 'price-green' : 'price-red'" growing-ignore="true">{{
                        symbol.price_change_pct.replace('%', '') > 0 ?
                            `+${symbol.price_change_pct}`:`-${symbol.price_change_pct}` }}</span>
                    <em v-else :class="symbol.up ? 'bg-up' : 'bg-down'" growing-ignore="true">{{ symbol.price_change_pct }}</em>
                </dd>
            </dl>
        </section>
    </div>
</template>

<script>
import { getAppContext } from 'utilities/helper';
import TokenIcon from '../../Components/TokenIcon.vue';

export default {
    components: { TokenIcon },
    props: ['title', 'symbols', 'typeFilter', 'searchTerm','futures'],
    computed: {
        filteredSymbols: function () {
            console.log(this.symbols,'this.symbols');
            if (!this.symbols) {
                return [];
            }

            // Determines if test mode is enabled or not for the current user.
            const context = getAppContext();
            let testMode = false;
            if (context && context.profile) {
                testMode = context.profile.testEnabled === true;
            }

            const self = this;
            const term =
                this.searchTerm && typeof this.searchTerm === 'string'
                    ? this.searchTerm.trim().toUpperCase() // Always use upper case
                    : '';
            if (term && term.length > 0) {
                let data = this.symbols.filter((s) => {
                    return s.metadata.type === self.typeFilter && (testMode || !s.metadata.testOnly) && s.metadata.name.indexOf(term) >= 0;
                });
                if(this.$route.path==='/'){
                    data= data.slice(0,5);
                }
                return data
            } else {
                // Return symbols for the specified type.
                let data = this.symbols.filter((s) => {
                    return s.metadata.type === self.typeFilter && (testMode || !s.metadata.testOnly);
                });
                if(this.$route.path==='/'){
                    data= data.slice(0,5);
                }
                return data
            }
        }
    },
    methods: {
        gotoTrade: function (sym) {
            this.$emit('symbol-selected', sym);
        },

        getVisibleSids: function () {
            const output = [];
            $(this.filteredSymbols).each((index, sym) => {
                output.push(sym.metadata.id);
            });

            return output;
        }
    }
};
</script>
<style scoped>
.homepage-market,.homepage-market-sm{
    color: #000;
}
.homepage-market .market-wrap dl dd a .buy span {
    background: #fff ;
    border-radius: 20px;
    border:1px solid #674EDE ;
    color: #674EDE;
}
#futures_symbolList .pe-2{
    display: none;
}
#futures_symbolList .price-green {
    color: #58bd7d;
}

#futures_symbolList .price-red {
    color: #ff6838;
}
#futures_symbolList #futures-symname{
    flex: 0.4;
}
#futures_symbolList #futures-price {
    text-align: left;
    white-space: wrap;
    flex: 0.25;
}

#futures_symbolList #futures-change {
    text-align: right;
    white-space: wrap;
    flex: 0.25;
}
#futures-symname{
    flex: 0.33;
}
#futures-price {
    text-align: center;
    white-space: wrap;
    flex: 0.33;
}
#futures-change{
    text-align: right;
    white-space: wrap;
    flex: 0.33;
}
#futures_symbolList dd.title>* {
    font-size: 12px;
    line-height: 1.5rem;
    font-weight: 600;
}
.sort-column{
    display: inline-block;
    color: #aaa; 
    font-size: 14px; 
    position: relative;
    padding-right: 20px; 
}
.sort-column::after {
    content: '\25B2'; 
    display: inline-block;
    font-size: 10px; 
    color: #b3b3b3; 
    position: absolute;
    right: 5px; 
    top: -3px; 
}

.sort-column::before {
    content: '\25BC'; 
    display: inline-block;
    font-size: 10px; 
    color: #b3b3b3; 
    position: absolute;
    right: 5px; 
    bottom: -5px; 
}
@media screen and ( min-width :768px) and ( max-width :1024px) {
    .homepage-market-sm{
        display:  block !important;
    }
    .homepage-market{
        display:  none !important;
    }
}
@media(max-width :1024px){
    .quotes-page .homepage-market-sm > dl > dd{
        font-size: 10px;
    }
}
@media(min-width :1024px){
    #futures_symbolList.homepage-market-sm {
        display: block !important;
    }

    #futures_symbolList.homepage-market {
        display: none !important;
    }
}
</style>