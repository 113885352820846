<template>
    <section class="page login-page layout-login d-flex">
        <div class="d-flex flex-row login-left-right flex-fill">
            <div class="login-page-right login-container">
                <div class="login-box">
                    <div class="login container ">
                        <div class="login-inner" id="login-page">
                            <loading-indicator v-if="!regions" />
                            <template v-else>
                                <reset-password-component v-if="!reset_completed" :regions="regions" v-on:reset-completed="reset_completed = true" />
                                <div v-else class="container mt-5">
                                    <div class="row">
                                        <div class="col">
                                            <div class="alert alert-info">
                                                <div class="p form-group">
                                                    <div class="col">{{ $t('reset_password.succeeded') }}</div>
                                                </div>
                                                <div class="p form-group">
                                                    <div class="col">
                                                        <router-link to="/user/login">{{ $t('reset_password.label_login') }}</router-link>
                                                        |
                                                        <router-link to="/">{{ $t('reset_password.label_go_home') }}</router-link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<style scoped lang="less">
.sysconfig-name {
  display: flex;
  align-items: center;
  margin-bottom: 33px;
  height: 25px;
  h2 {
    font-family: Plus Jakarta Sans, Plus Jakarta Sans;
    font-weight: 500;
    font-size: 21px;
    color: #0E0637;
    margin-bottom: 0;
  }
}
.login-title {
  padding: 0;
  font-family: PingFang HK, PingFang HK;
  font-weight: 500;
  font-size: 27px;
  color: #000000;
  line-height: 56px;
  padding-top: 6px;
}
.login-des {
  margin-top: 8px;
  font-family: PingFang HK, PingFang HK;
  font-weight: 400;
  font-size: 17px;
  color: #717184;
  line-height: 28px;
  margin-bottom: 40px;
}
.login-left-right {
  margin: auto;
  width: 100%;
  background-image: url(../../assets/images/es-dgen/loginBc.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
}
.login-container {
  background: none;
  display: flex;
  align-items: center;
  justify-content: end;
}
.img 
.login-page .login h1 {
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 26px;
  color: #3d3d3d;
  text-align: start;
  padding-top: 0;
  padding-bottom: 4rem;
}
.login-box {
  display: flex;
  justify-content: end;
}
.right-content {
  background: #f8f9ff;
  position: relative;
  width: 320px;
  min-height: 550px;
  display: inline-block;
  margin-top: 200px;
  padding: 15px;
  margin-bottom: 100px;
}
.right-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 24px;
  color: #3d3d3d;
  border-bottom: 1px solid #d8d8d8;
  min-height: 40px;
  margin-top: 110px;
}
.right-boby {
  margin-top: 10px;
}
.right-boby > div {
  text-align: left;
  padding: 2px 10px;
}
.right-boby > div:nth-child(2) {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 16px;
  color: #2c2236;
}
.right-boby > div:nth-child(3) {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: #2c2236;
}
@media (max-width: 1024px) {
  .login-box {
    min-width: inherit;
    width: 100%;
  }
  .login-container {
    background: #fff;
  }

  .login-container .img {
    display: none;
  }


  .login-page .login h1 {
    color: #000;
  }

}
@media (max-width: 767px) {
  #login-page .row {
    display: none;
  }
  .img-box-md {
    width: 100%;
    margin-top: -20px;
    .img-award-md {
      width: 100%;
      height: auto;
    }
  }
  .login-container {
    display: block;
  }
  .login-box {
    padding-left: 0;
    position: relative;
    z-index: 9;
    background-image: url(../../assets/images/es-dgen/loginBcMd.png);
    background-size: contain;
    background-repeat: repeat;
    background-position: top center;
    height: auto;
    .login {
      max-width: none;
      background-color: #fff;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      padding: 30px 50px;
      margin-top: 200px;
    }
  }
}
</style>

<script type="text/javascript">
import ResetPasswordComponent from './Components/ResetPassword.vue';

export default {
    components: { ResetPasswordComponent },

    data() {
        return { regions: null, reset_completed: false };
    },

    created() {
        const self = this;
        $.callGetApi(this, '/api/v1/config/regions').then((resp) => {
            if (resp) {
                self.regions = resp.data;
            }
        });
    },

    watch: {
        '$i18n.locale': function () {
            this.reloadRegionsAsync();
        }
    },

    methods: {
        reloadRegionsAsync: async function () {
            // Read a list of supported regions.
            const resp = await $.callGetApi(self, '/api/v1/config/regions');
            if (resp && resp.data) {
                this.regions = resp.data;
            }
        }
    }
};
</script>