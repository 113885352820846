<template>
    <footer class="footer">
        <div class="footer-inner container">
            <div class="footer-right" v-if="footer">
                <dl v-for="(footer_item, index) in footer" :key="index">
                    <dt>{{ footer_item.title }}</dt>
                    <dd>
                        <router-link v-for="item in footer_item.items" :key="item.id" rel="noopener noreferrer nofollow" :to="'/post/' + item.id">{{ item.title }}</router-link>
                    </dd>
                </dl>
            </div>
            <div class="footer-languages" v-if="langs.length > 0">
                <div v-if="footer && footer.length > 0">
                    <router-link v-for="item in footer[0].items" :key="item.id" rel="noopener noreferrer nofollow" :to="'/post/' + item.id">{{ item.title }}</router-link>
                </div>
                <div>
                    <a v-for="(lang, index) in langs" :key="index" @click="changeLang(lang)" :disabled="$i18n.locale === lang" href="javascript:;">
                        {{  getLocaleName(lang)==='pt'?'Português':getLocaleName(lang) }}
                    </a>
                </div>
            </div>
            <div>
                <div class="footer-logo"><img src="/image/sitelogosm" :alt="sysconfig.name" /></div>
                <div>
                    &copy; 2024 {{ sysconfig.name }}
                    <br />
                    {{ $t('home.footer_support_email') }}: {{ sysconfig.email }}
                </div>
                <div>
                    <a :href="sysconfig.kvConfigs.twitter">
                        <svg class="ft-icon" viewBox="0 0 256 256">
                            <use xlink:href="/dist/svg/icons.svg#icon-twitter" />
                        </svg>
                    </a>

                    <a :href="sysconfig.kvConfigs.facebook">
                        <svg class="ft-icon" viewBox="0 0 256 256">
                            <use xlink:href="/dist/svg/icons.svg#icon-facebook" />
                        </svg>
                    </a>
                    <a :href="sysconfig.kvConfigs.instagram">
                        <svg class="ft-icon" viewBox="0 0 256 256">
                            <use xlink:href="/dist/svg/icons.svg#icon-instagram" />
                        </svg>
                    </a>
                    <a :href="sysconfig.kvConfigs.youtube">
                        <svg class="ft-icon" viewBox="0 0 256 256">
                           <use xlink:href="/dist/svg/icons.svg#icon-youtube" />
                        </svg>
                    </a>
                    <a :href="sysconfig.kvConfigs.medium">
                        <svg class="ft-icon" viewBox="0 0 256 256">
                            <use xlink:href="/dist/svg/icons.svg#icon-medium" />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { getAppContext } from 'utilities/helper';
import '@/assets/svg/logo/icon-twitter.svg';
import '@/assets/svg/logo/icon-facebook.svg';
import '@/assets/svg/logo/icon-instagram.svg';
import '@/assets/svg/logo/icon-youtube.svg';
import '@/assets/svg/logo/icon-medium.svg';
export default {
    data() {
        return { langs: [], context: null };
    },

    computed: {
        footer: function () {
            return this.context.homeConfig ? this.context.homeConfig.footer : null;
        }
    },

    created() {
        this.langs = Object.freeze(this.sysconfig.supportedLanguages);
        this.context = getAppContext();
    },

    methods: {
        changeLang: function (lang) {
            $.changeLang(this, lang);
        }
    }
};
</script>
<style scoped>
.footer {
    position: relative;
}
.ft-icon {
    fill: #fff;
    stroke: #000;
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
    margin-right: .5rem;
}
</style>