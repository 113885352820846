<template>
    <div class="section">
        <div class="section1 d-flex">
            <div class="left-box">
                <h1>{{$t('home.home_section1_title')}}</h1>
                <div class="subtitle">{{$t('home.home_section1_subtitle')}}</div>
                <div class="footer-box d-flex">
                    <img class="coin-img" src="@/assets/images/es-dgen/section1_coin.png" />
                    <div class="text-box">
                        <div>{{$t('home.home_section1_footer_tips')}}</div>
                        <div>{{$t('home.home_section1_footer_tips1')}}</div>
                    </div>
                </div>
            </div>
            <div class="right-box">
                <div class="img-box img-mobile-box">
                    <div class="btn-box">
                        <button @click="goLogin" class="begin-btn">{{$t('home.home_section1_button_label')}}</button>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="section2">
            <h2 class="title">{{$t('home.home_section2_title')}}</h2>
            <div class="section2-box">
                <h2 class="pc-title">{{$t('home.home_section2_title')}}</h2>
                <div class="left">
                <div >
                    <img class="img" src="@/assets/images/es-dgen/setting.png" />
                </div>
                <div class="text">
                    <div><span>{{$t('home.home_section2_subtitle')}}</span>{{$t('home.home_section2_sub_content')}}</div>
                </div>
                </div>
                <div class="right">
                    <div>
                        <img class="img" src="@/assets/images/es-dgen/shark.png" />
                    </div>
                    <div class="text">
                        <div><span>{{$t('home.home_section2_subtitle1')}}</span>{{$t('home.home_section2_sub_content1')}}</div>
                    </div>
                </div>
            </div>
            
        </div>
         <div class="section3">
            <div class="black-box">
                <div class="title">{{ $t('home.home_section3_title') }}</div>
                <div class="subtitle">{{ $t('home.home_section3_context') }}</div>
                <button @click="goIdverify" class="idverify-btn">{{ $t('home.home_section3_context') }}</button>
                <div class="card-box d-flex justify-content-evenly">
                    <div class="card-item">
                        <img class="img" src="@/assets/images/es-dgen/section3_bg1.png" />
                        <div class="text">{{ $t('home.home_section3_subtitle') }}</div>
                        <button class="order-now" @click="goSaving">{{ $t('home.home_section3_button_label') }}</button>
                    </div>
                    <div class="card-item">
                        <img class="img" src="@/assets/images/es-dgen/section3_bg2.png" />
                        <div class="text">{{ $t('home.home_section3_subtitle') }}</div>
                        <button class="order-now" @click="goSaving">{{ $t('home.home_section3_button_label') }}</button>
                    </div>
                    <div class="card-item mobile-card-item">
                        <img class="img" src="@/assets/images/es-dgen/section3_bg3.png" />
                        <div class="text">{{ $t('home.home_section3_subtitle') }}</div>
                        <button class="order-now" @click="goSaving">{{ $t('home.home_section3_button_label') }}</button>
                    </div>
                </div>
                <button @click="goSaving" class="more">
                    {{ $t('home.home_section3_button_label1') }}
                    <img class="img ml-5" src="@/assets/images/es-dgen/arrow.png" />
                </button>
            </div>
         </div>
         <div class="section4 d-flex">
            <img class="bg-img" src="@/assets/images/es-dgen/section4_bg.png" />
            <img class="mobile-img" src="@/assets/images/es-dgen/section4_bg_mobile.png" />
            <div class="right-box">
                <div class="title">{{ $t('home.home_section4_title') }}</div>
                <div class="subtitle">{{ $t('home.home_section4_subtitle') }}</div>
                <div class="content-box">
                    <img class="img" src="@/assets/images/es-dgen/tick.png" />
                    <span>{{ $t('home.home_section4_context1') }}</span>
                </div>
                <div class="content-box">
                    <img class="img" src="@/assets/images/es-dgen/tick.png" />
                    <span>{{ $t('home.home_section4_context2') }}</span>
                </div>
                <div class="content-box">
                    <img class="img" src="@/assets/images/es-dgen/tick.png" />
                    <span>{{ $t('home.home_section4_context3') }}</span>
                </div>
                <button @click="goTrade" class="go-trade">
                    {{ $t('home.home_section4_button_label') }}
                    <img class="img ml-5" src="@/assets/images/es-dgen/bidirection-white.png" />
                </button>
            </div>
         </div>
         <div class="section5">
            <div class="title">{{ $t('home.home_section5_title') }}</div>
            <div class="black-card-box d-flex justify-content-evenly">
                <div class="black-card-item mr-50">
                    <div class="subtitle-box">
                        <img class="img" src="@/assets/images/es-dgen/star.png" />
                        <span>{{ $t('home.home_section5_subtitle1') }}</span>
                    </div>
                    <div class="content-box">{{ $t('home.home_section5_context1') }}</div>
                </div>
                <div class="black-card-item mr-50">
                    <div class="subtitle-box">
                        <img class="img" src="@/assets/images/es-dgen/star.png" />
                        <span>{{ $t('home.home_section5_subtitle2') }}</span>
                    </div>
                    <div class="content-box">{{ $t('home.home_section5_context2') }}</div>
                </div>
                <div class="black-card-item">
                    <div class="subtitle-box">
                        <img class="img" src="@/assets/images/es-dgen/star.png" />
                        <span>{{ $t('home.home_section5_subtitle3') }}</span>
                    </div>
                    <div class="content-box">{{ $t('home.home_section5_context3') }}</div>
                </div>
            </div>
           <div class="text-box">
                <div>
                    {{ $t('home.home_section5_context4') }} 
                </div>
                <div>
                    {{ $t('home.home_section5_context5') }} 
                </div>
           </div>
           <button @click="goTrade" class="go-trade-btn">
            {{ $t('home.home_section4_button_label') }}
            <img class="img ml-5" src="@/assets/images/es-dgen/bidirection-black.png" />
           </button>
         </div>
         <div class="section6 d-flex justify-content-center">
            <div class="left-box">
                <div class="title">{{ $t('home.home_section6_title') }}</div>
                <div class="subtitle">{{ $t('home.home_section5_context4') }}{{ $t('home.home_section5_context5') }}</div>
                <button @click="goRegister" class="register">
                    {{ $t('general.register') }}
                    <img class="img" src="@/assets/images/es-dgen/white-arrow.png" />
                </button>
            </div>
            <img class="right-img" src="@/assets/images/es-dgen/section6_bg.png" alt="">
            <img class="mobile-img" src="@/assets/images/es-dgen/section6_bg_mobile.png" alt="">
         </div>
    </div>
</template>
<script>
import Deposit from '../../Finance/Deposit.vue'
export default {
  components: { Deposit },
    data() {
        return {
           
        }
    },
    created() { },
    methods: {
        goLogin(){
            this.$router.push('/user/login')
        },
        goRegister(){
            this.$router.push('/user/create')
        },
        goTrade(){
            this.$router.push('/futures')
        },
        goIdverify(){
            this.$router.push('/user/verifyid')
        },
        goSaving(){
            this.$router.push('/saving')
        }
    }
}
</script>
<style lang="less" scoped>
.section{
    width: 100%;
    .section1,.section2,.section3,.section4{
        padding:0 42px;
    }
    .section1{
        min-height: 339px;
        .left-box{
            padding-top: 32px;
            position: relative;
            
            h1{
                font-size: 32px;
                font-weight: 600;
                margin-bottom: 35px;
                color: #333333;
            }
            .subtitle{
                font-size: 14px;
                font-weight: normal;
                color: #78787D;
                margin-bottom: 73px;
            }
            .coin-img{
                width: 73px;
                height: 30px;
            }
            .text-box{
                font-size: 10px;
                margin-left: 12px;
            }
            .footer-box{
                position: absolute;
                bottom: 11px;
            }
        }
        .right-box{
            width: 50%;
            position: relative;
            .img-box{
                width: 345px;
                height: 339px;
                background:url('@/assets/images/es-dgen/section1_bg.png') no-repeat;
                background-size: 100% 100%;
                position: absolute;
                right: 0px;
                .btn-box{
                    position: absolute;
                    bottom: 60px;
                    left: 21px;
                    width: 126px;
                    height: 36px;
                    padding: 4px;
                    border-radius: 18px;
                    background-color: #000000;
                }
                .begin-btn{
                    border: 1px solid #fff;
                    color: #ffffff;
                    font-size: 12px;
                    background-color: #000000;
                    width: 100%;
                    height: 100%;
                    border-radius: 14px;
                }
            }
            
        }
    }
    .section2{
        margin-top: 96px;
        .section2-box{
            display: flex;
            justify-content: space-evenly;
            
        }
        justify-content: space-evenly;
        .left,.right{
                .img{
                    width: 22px;
                    height: 22px;
                }
                .text{
                    margin-top: 9px;
                }
            }
        .title,.pc-title{
            text-align: center;
            font-family: PingFang HK, PingFang HK;
            font-weight: 500;
            font-size: 28px;
            color: #0E0637;
        }
        .title{
            display: none;
        }
        .pc-title{
            display: block;
        }
        .content{
            width: 100%;
            min-height: 200px;
            display: flex;
            margin-top: 50px;
           
            .left{
                margin-right: 2%;
                .img{
                    width: 100%;
                    height: 60%;
                    background:url('@/assets/images/EU6/Illustration@2x.png') no-repeat;
                    background-size: 100% 100%;
                }
                .text{
                    height: 40%;
                    width: 100%;
                    padding: 10px 20px;
                    div:nth-child(1){
                        font-family: PingFang HK, PingFang HK;
                        font-weight: 500;
                        font-size: 20px;
                        color: #0E0637;
                        margin-top: 20px;
                    }
                    div:nth-child(2){
                        font-family: PingFang HK, PingFang HK;
                        font-weight: 500;
                        font-size: 14px;
                        color: #42424D;
                    }
                }
            }
            .right{
                margin-right: 2%;
                .img{
                    width: 100%;
                    height: 60%;
                    background:url('@/assets/images/EU6/Supercharge@2x.png') no-repeat;
                    background-size: 100% 100%;
                }
                .text{
                    height: 40%;
                    width: 100%;
                    padding: 10px 20px;
                    div:nth-child(1){
                        font-family: PingFang HK, PingFang HK;
                        font-weight: 500;
                        font-size: 20px;
                        color: #0E0637;
                        margin-top: 20px;
                    }
                    div:nth-child(2){
                        font-family: PingFang HK, PingFang HK;
                        font-weight: 500;
                        font-size: 14px;
                        color: #42424D;
                    }
                }
            }
        }
    }
    .section3{
        margin-top: 82px;
        .black-box{
            padding: 52px 42px 52px 42px;
            background-color: #0D1218;
            width: 100%;
            border-radius: 30px;
            .title{
                text-align: center;
                color: #fff;
                font-size: 22px;
            }
            .subtitle{
                text-align: center;
                color: #fff;
                font-size: 14px;
                margin-top: 10px;
            }
            .idverify-btn{
                border: 1px solid #fff;
                color: #fff;
                font-size: 12px;
                border-radius: 20px;
                padding: 10px 20px;
                margin: 0 auto;
                background-color: #0D1218;
                display: flex;
                margin-top: 15px;
                margin-bottom: 52px;
            }
            .card-box{
                margin-bottom: 36px;
                .card-item{
                    background: #fff;
                    padding: 12px;
                    border-radius: 10px;
                    .text{
                        text-align: center;
                        margin-top: 15px;
                        color: #333333;
                        font-size: 16px;
                        font-weight: 500;
                    }
                    
                }
            }
            .order-now,.more{
                padding: 10px 18px;
                background-color: #fff;
                color: #333333;
                font-size: 14px;
                font-weight: 500;
                border: 1px solid #333;
                border-radius: 20px;
                display: flex;
                margin: 0 auto;
                margin-top: 10px;
            }
            .more{
                border: none;
                min-width: 81px;
                justify-content: center;
                .img{
                    width: 20px;
                }
            }
            
        }
    }
    .section4{
        margin-top: 82px;
        justify-content: center;
        .bg-img{
            width: 390px;
        }
        .title{
            font-size: 32px;
            font-weight: 500;
            margin-bottom: 12px;
        }
        .subtitle{
            color: #666666;
            margin-bottom: 18px;
            font-size: 14px;
            font-weight: 400;
        }
        .right-box{
            .content-box{
                margin-bottom: 18px;
                .img{
                    margin-right: 2px;
                }
            }
            .go-trade{
                margin-top:15px ;
                background-color: #000000;
                color: #fff;
                display: flex;
                border-radius: 20px;
                padding: 8px 18px;
            }
        }
    }
    .section5{
        background-color: #0D1218;
        padding: 62px 42px;
        .title{
            color: #fff;
            text-align: center;
            margin-bottom: 34px;
            font-size: 25px;
            font-weight: 600;
        }
        .black-card-box{
            width: 100%;
            .black-card-item{
                color: #fff;
                width: 33.3%;
                background-color:#141B24;
                padding: 35px 15px;
                border-radius: 20px;
                .content-box{
                    margin-top: 22px;
                }
            }
            .mr-50{
                margin-right: 50px;
            }
        }
        .text-box{
            text-align: center;
            color: #7F8490;
            font-size: 14px;
            margin-top: 36px;
        }
        .go-trade-btn{
            background-color: #fff;
            color: #333333;
            font-weight: 600;
            display: flex;
            border-radius: 20px;
            padding: 8px 18px;
            margin: 15px auto;
        }
    }
    .section6{
        padding: 15px 42px;
        .left-box{
            padding-top: 25px;
            padding-right: 37px;
            .title{
                font-size: 24px;
                font-weight: 600;
                line-height: 48px;
                letter-spacing: 0px;
                color: #242428;
                margin-bottom: 20px;
            }
            .subtitle{
                font-size: 12px;
                font-weight: 500;
                color: #7F8490;
                margin-bottom: 23px;
            }
            .register{
                margin-top:15px ;
                background-color: #000000;
                color: #fff;
                display: flex;
                border-radius: 20px;
                padding: 8px 18px;
                min-width: 86px;
                .img{
                    width: 21px;
                    margin-left: 5px;
                }
            }
        }
        .right-img{
            width: 261px;
        }
    }
    .ml-5{
        margin-left: 5px;
    }
    .mobile-img{
        display: none;
    }
}
@media (max-width:768px) {
    .section{
        .section1,.section2,.section3,.section4{
            padding:0 20px;
        }
        .black-box{
            padding: 52px 22px 52px 22px;
        }
        .section1{
            min-height: 180px;
            .left-box{
                width: 52%;
                margin-right: 10px;
                h1{
                    font-size: 16px;
                    margin-bottom: 15px;
                }
                .subtitle{
                    font-size: 10px;
                    margin-bottom: 20px;
                }
            }
            .right-box{
                .img-mobile-box{
                    width: 184px;
                    height: 174px;
                    background:url('@/assets/images/es-dgen/section1_bg_mobile.png') no-repeat;
                    background-size: 100% 100%;
                    position: absolute;
                    right: 0px;
                    .btn-box{
                        bottom: 24px;
                        left: 12px;
                        width: 70px;
                    }
                }
            }
        }
        .section2{
            .title{
                display: block;
                text-align: left;
            }
            .section2-box{
                .pc-title{
                    display: none;
                }
            }
            
        }
        .section5{
            .black-card-box{
                .black-card-item{
                    display: none;
                }
                .black-card-item:first-child{
                    display: block;
                    width: 100%;
                    margin-right: 0;
                }
            }
        }
        .section3{
            .black-box{
                .card-box{
                    .mobile-card-item{
                        display: none;
                    }
                    .card-item{
                        width: 48%;
                        .img{
                            width: 100%;
                        }
                    }
                }
            }
        }
        .section4{
            padding-bottom: 18px;
            .mobile-img{
                display: block;
                width:195px;
            }
            .bg-img{
                display: none;
            }
            .right-box{
                .title{
                    font-size: 18px;
                }
                .subtitle,.content-box{
                    font-size: 10px;
                }
                .content-box{
                    margin-bottom: 5px;
                }
            }
        }
        .section6{
            .mobile-img{
                display: block;
                width: 130px;
            }
            .right-img{
                display: none;
            }
            
        }
    }
    
}
@media (max-width:1024px) {
    .mobile-card-item{
        display: none;
    }
}

</style>
